import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Link} from "@mui/material";
import theme from "../../theme";

const myStyles = {
	link: {
		fontSize:'1.3rem',
		color: 'white',
		textDecoration: 'none',
		margin: theme.spacing(1, 1.5),
		[theme.breakpoints.down("sm")]: {
			fontSize:'1rem',
		},
		'&:hover': {
			color: theme.palette.primary.dark,
			textDecoration: 'none'
		}
	}
};

function Menu(props) {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const [errors, setErrors] = useState({});

	return (
		<>
			<Link target="blank" variant="button" color="textPrimary" href="https://portail.nessapp.ca" sx={myStyles.link}>
				{t("menu.Login")}
			</Link>
		</>
	);
}

export default Menu;