require('dotenv').config({ path: '../.env' });

const TPS = process.env.TPS ? parseFloat(process.env.TPS) : 0.05
const TVQ = process.env.TVQ ? parseFloat(process.env.TVQ) : 0.09975
const PORTAIL_URL = process.env.PORTAIL_URL ? process.env.PORTAIL_URL : 'localhost:3002/'

module.exports = {
	PORTAIL_URL,
	TPS,
	TVQ
}