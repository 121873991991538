import React from 'react';
import {Link, Typography} from "@mui/material";

const myStyles = {
	toolbarTitle: {
		flexGrow: 1,
		textDecoration: 'none',
		"&:hover": {
			textDecoration: 'none',
			color:'white'
		}
	},
}

function Logo(props) {
	return (
		<Typography component={Link} to='/' variant="h6" color="textPrimary" noWrap sx={myStyles.toolbarTitle}>
			NessaApp
		</Typography>
	);
}

export default Logo;