const React = require("react");
const NumberFormat = require("react-number-format");


const placeDollars = (element) => {
	const currentLanguage = localStorage.getItem("i18nextLng") || "fr"
	const price = Number(element).toFixed(2)
	return currentLanguage === 'fr' ? "$" + price : price + "$"
}

module.exports = {
	placeDollars,
}