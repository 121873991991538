import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Divider,
    Grid,
    Icon,
    IconButton, styled, Tooltip,
    Typography
} from "@mui/material";
import theme from "../../../theme";
import VerifiedIcon from '@mui/icons-material/Verified';


const planList = [
    {
        id: 1,
        title: "home.pricing.Individuel",
        specialPrice: 9.99,
        regularPrice: 14.99,
        bureauVirtuel: 1,
        compteUtilisateur: 1,
        ficheClient: 300,
        href:'/inscription/1',
    },
    {
        id: 2,
        title: "home.pricing.Standard",
        specialPrice: 29.99,
        regularPrice: 34.99,
        bureauVirtuel: 1,
        compteUtilisateur: 10,
        ficheClient: 2500,
        href:'/inscription/2',
    },
    {
        id: 3,
        title: "home.pricing.Superieur",
        specialPrice: 49.99,
        regularPrice: 44.99,
        bureauVirtuel: 2,
        compteUtilisateur: 25,
        ficheClient: 7500,
        href:'/inscription/3',
    },
    {
        id: 4,
        title: "home.pricing.Prenium",
        specialPrice: 69.99,
        bureauVirtuel: 5,
        compteUtilisateur: '26+',
        ficheClient: 50000,
        href:'/inscription/4',
    },
];

const myStyles = {
    button:{
        width: '100%',
        fontSize: '1.2rem',
        color: theme.palette.primary.dark,
        backgroundColor: 'transparent',
        border:'2px solid',
        borderColor: theme.palette.primary.dark,
        borderRadius: '15px',
        '&:hover': {
            backgroundColor: 'transparent',
            color:theme.palette.primary.dark,
        }
    },
    card: {
        padding: "1rem",
        border: `1px solid ${theme.palette.background.default}`,
        borderRadius: 8,
        zIndex: 1,
        transition: "all 400ms",
        minHeight: "400px",
        "&:hover": {
            border: `1px solid ${theme.palette.primary.main}`,
            boxShadow: theme.shadows[6],
            "& [class^='MuiButtonBase-']": {
                background: theme.palette.primary.main + "!important",
                "& [class^='MuiSvgIcon-']": {
                    fill: theme.palette.primary.contrastText + "!important",
                },
            },
        },
    },
    section: {
        paddingTop: '5rem',
        paddingBottom: '5rem',
        background: theme.palette.primary.main,
    },
};

const PricingHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '120px',
}));

const PricingDetails = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
    marginBottom: '1rem',
    minHeight: '170px',
    textAlign:'left'
}));

const PricingDetail = styled('div')(({ theme }) => ({
    display: 'flex',
    margin:'8px 0'
}));

const PricingTitle = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2rem',
}));


function Pricing(props) {
    const {t} = useTranslation();
    const [errors, setErrors] = useState({});

    useEffect(() => {
    }, []);

    return (
        <>
            <Box id="pricing" sx={myStyles.section}>
                <Container justify="center" sx={{pb:3}}>
                    <PricingTitle>
                        <Typography variant="h2">
                            {t('home.pricing.PricingTitle')}
                        </Typography>
                        <Typography variant="body2" gutterBottom sx={{color:theme.palette.primary.dark}} >
                            {t('home.pricing.PricingSubtitle')}
                        </Typography>
                    </PricingTitle>
                    <Grid container spacing={3}>
                        {planList.map((plan, ind) => (
                            <Grid key={ind} item md={6} lg={3} xs={12}>
                                <Card sx={myStyles.card} elevation={3}>
                                    {
                                        plan.id === 2 && (
                                            <div class="pricingSelection">
                                                <Tooltip title={t('home.pricing.Best')} sx={{color:theme.palette.primary.main, fontSize: "64px", float:'right'}} >
                                                    <VerifiedIcon />
                                                </Tooltip>

                                            </div>
                                        )
                                    }
                                    <PricingHeader>
                                        <div>
                                            <Typography variant="h4" align="center" >
                                                {t(plan.title)}
                                            </Typography>
                                            <Typography variant="body1" align="center" >
                                                {plan.id === 4 ? (
                                                    <Box component="span" sx={{ fontWeight: 'body1', fontSize: '1.2rem' }}>{t('home.pricing.StartingAt')}</Box>
                                                ): (
                                                    <Box component="span" sx={{ fontWeight: '500', color:'red', fontSize: '1.5rem', textDecoration:'line-through' }}>{plan.regularPrice}${t('home.pricing.ParMois')}</Box>
                                                )}
                                                <br/>
                                                <Box component="span" sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}>{plan.specialPrice}${t('home.pricing.ParMois')}</Box>
                                            </Typography>
                                        </div>
                                    </PricingHeader>
                                    <Divider className="bg-primary"/>
                                    <PricingDetails>
                                        <PricingDetail>
                                            <Icon sx={{marginRight: '7px'}} color="primary">done</Icon>
                                            <Typography variant="body1" align="left" >
                                                { plan.bureauVirtuel > 1 ? plan.bureauVirtuel + " " + t('home.pricing.BureauxVirtuels') : "1 " + t('home.pricing.BureauVirtuel') }
                                            </Typography>
                                        </PricingDetail>
                                        <PricingDetail>
                                            <Icon sx={{marginRight: '7px'}} color="primary">done</Icon>
                                            <Typography variant="body1" align="left" >
                                                { plan.id === 4 ? (
                                                        plan.compteUtilisateur + " " + t('home.pricing.ComptesUtilisateurs')
                                                    ) : (
                                                        plan.compteUtilisateur > 1 ? plan.compteUtilisateur + " " + t('home.pricing.ComptesUtilisateurs') : "1 " + t('home.pricing.CompteUtilisateur')
                                                    )
                                                }
                                            </Typography>
                                        </PricingDetail>
                                        <PricingDetail>
                                            <Icon sx={{marginRight: '7px'}} color="primary">done</Icon>
                                            <Typography variant="body1" align="left" >
                                                {t(plan.ficheClient)} {t('home.pricing.FichesClients')}
                                            </Typography>
                                        </PricingDetail>
                                    </PricingDetails>
                                    <Box sx={{textAlign:'center'}}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            component="a"
                                            href={plan.href}
                                            sx={myStyles.button}
                                        >
                                            {t('home.pricing.Inscription')}
                                        </Button>
                                    </Box>

                                </Card>
                            </Grid>
                        ))}
                        <Grid item xs={12} >
                            <Typography variant="body2" align="left" sx={{color:'white'}} >
                                {t('home.pricing.TrialTitle')}
                            </Typography>
                            <Typography variant="body2" align="left" sx={{color:'white', fontSize:'0.8rem'}} >
                                {t('home.pricing.TrialDescription')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <Typography variant="body2" align="left" sx={{color:'white', fontSize:'0.8rem'}} >
                                {t('home.pricing.PricingOfferTitle')}
                            </Typography>
                            <Typography variant="body2" align="left" sx={{color:'white', fontSize:'0.8rem'}} >
                                {t('home.pricing.PricingOfferDescription')}
                            </Typography>
                        </Grid>

                    </Grid>
                </Container>
            </Box>
        </>
    );
}

export default Pricing;