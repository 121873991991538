import styled from '@emotion/styled';
import {TextField} from '@mui/material';

export const BaseTextField = styled(TextField)( ({theme}) => ({
	'& label.Mui-focused': {
		color: theme.palette.primary.main,
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: theme.palette.primary.main,
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: theme.palette.primary.dark,
		},
		'&:hover fieldset': {
			borderColor: theme.palette.primary.main,
		},
		'&.Mui-focused fieldset': {
			borderColor: theme.palette.primary.main,
		},
	},
}));
