import React from 'react';
import {useTranslation} from 'react-i18next';
import loginImg from '../../asset/images/logo.png'
import {Button, Container, Grid, Typography} from "@mui/material";
import HeroLayout from "./HeroLayout";
import theme from "../../theme";

const myStyles = {
    button:{
        fontSize: '1.75rem',
        minWidth: 200,
        color: theme.palette.common.white,
        backgroundColor: 'transparent',
        border:'2px solid',
        borderColor: theme.palette.common.white,
        borderRadius: '15px',
        '&:hover': {
            backgroundColor: 'transparent',
            color:theme.palette.primary.dark,
        }
    },
    icon:{
        color: theme.palette.common.white,
        fontSize: '1.75rem',
        '&:hover': {
            color:theme.palette.primary.dark,
        }
    }
}

function Hero(props) {
    const {t} = useTranslation();

    return (
        <HeroLayout>
            <img
                src={loginImg}
                alt="increase priority"
                className="hero-image"
            />
            <Container maxWidth='lg'>
                <Typography
                    color="inherit"
                    align="center"
                    variant="h2"
                    sx={{ mt: { sx: 4, sm:4} }}
                >
                    {t('home.HeroDescription')}
                </Typography>
                <Typography
                    color="inherit"
                    align="center"
                    variant="h2"
                >
                    {t('home.HeroDescription2')}
                </Typography>
                <Typography
                    color="inherit"
                    align="center"
                    variant="h2"
                    sx={{ mb: 4 }}
                >
                    {t('home.HeroDescription3')}
                </Typography>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={12} sx={{textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            size="large"
                            component="a"
                            href="/inscription/1"
                            sx={myStyles.button}
                        >
                            {t('home.HeroButton')}
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </HeroLayout>
    );
}

export default Hero;