import axios from 'axios'

class ApiClient {
	constructor(remoteHostUrl) {
		this.remoteHostUrl = remoteHostUrl
	}

	async request({ endpoint, method = 'GET', data = {}}) {
		const url = `${this.remoteHostUrl}/${endpoint}`
		const headers = {
			"Content-Type": "application/json",
		}

		try {
			const res = await axios({ url, method, data, headers })
			return { data: res.data, error: null }
		} catch (error) {
			console.error("APIclient.makeRequest.error:")
			console.error({ errorResponse: error.response })
			const message = error?.response?.data?.error?.message
			return { data: null, error: message || String(error) }
		}
	}

	async fetchSubscriptionTier(){
		return await this.request({endpoint: `subscriptiontier/fetchSubscriptionTier`, method: `GET`})
	}

	async registerCenter(center){
		return await this.request({endpoint: `auth/register`, method: `POST`, data: center} )
	}

	async requestInformation(client){
		return await this.request({endpoint: `apps/requestinfos`, method: `POST`, data: client} )
	}

	async submitRegistrationPayment(center, typeId){
		return await this.request({endpoint: `stripe/submitregistrationpayment`, method: `POST`, data: {center, typeId}} )
	}
}

export default new ApiClient(process.env.REACT_APP_REMOTE_HOST_URL || 'http://localhost:5000/api')