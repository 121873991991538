import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Box,
    Button, CircularProgress,
    Container,
    Grid,
    styled,
    Typography
} from "@mui/material";
import {BaseTextField} from "../../textfield/StyledTextField";
import theme from "../../../theme";
import apiClient from "../../../services/apiClient";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";

const myStyles = {
    button:{
        fontSize: '1.2rem',
        color: theme.palette.primary.dark,
        backgroundColor: 'transparent',
        border:'2px solid',
        borderColor: theme.palette.primary.dark,
        borderRadius: '15px',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color:theme.palette.primary.dark,
        }
    },
    section: {
        paddingTop: '5rem',
        paddingBottom: '5rem',
        overflow: 'hidden',
    },
    containerResquest:{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    submitContainer:{
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
}

const Title = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2rem',
}));

const FormRequestInfo = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto 0',

}));

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format="(###) ###-####" allowEmptyFormatting mask="_"
        />
    );
});

const initialState = {name : "", email:"", phone:""};

function CallToAction(props) {
    const {t} = useTranslation();
    const [errors, setErrors] = useState({});
    const [isProcessing, setIsProcessing] = useState(false)
    const [form, setForm] = useState(initialState);

    const handleChange = (element) => {
        if ( errors.hasOwnProperty(element.target.name) ) {
            setErrors((e) => ({ ...e, [element.target.name]: null }))
        }

        if (element.target.name === "email") {
            if (element.target.value.indexOf("@") === -1) {
                setErrors((e) => ({ ...e, email: "register.ValidEmail" }))
            } else {
                setErrors((e) => ({ ...e, email: null }))
            }
        }
        setForm({ ...form, [element.target.name]: element.target.value });
    };

    async function submitHandler() {
        const requiredFields = [
            'name',
            'email',
            'phone']
        let hasError = false;
        setIsProcessing(true)
        setErrors((e) => ({...e, form: null}))

        requiredFields.forEach(field => {
            if (!form.hasOwnProperty(field)) {
                setErrors((e) => ({...e, [field]: "app.RequiredFields"}))
                hasError = true
            }
        })

        Object.entries(form).map(field => {
            if (field[1] === '') {
                setErrors(
                    (e) => ({...e, [field[0]]: "register.RequiredFields"}))
                hasError = true
            }
        })

        if (!hasError) {
            const {data, error} = await apiClient.requestInformation(form)
            if (data) {
                Swal.fire({
                    title: t('home.RegistrationSuccess'),
                    html:t('home.RegistrationSuccessMessage'),
                    icon: 'success',
                    confirmButtonColor: theme.palette.primary.main,
                    timer: 5000,
                    timerProgressBar: true,
                })
            }
            if (error) {
                setErrors({form: error})
            }
            setForm(initialState)
        }
        setIsProcessing(false)
    }

    return (
        <Box id="requestInfo" sx={myStyles.section}>
            <Container sx={myStyles.containerResquest}>
                <Title>
                    <Typography variant="h2" >
                        {t('home.request.RequestInfoTitle')}
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{fontSize:'1.2em', color: theme.palette.primary.dark}}>
                        {t('home.request.RequestInfoSubtitle')}
                    </Typography>
                    {errors?.form && <span className="errorMsg">{t(errors.form)}</span>}
                </Title>
                <FormRequestInfo>
                    <Grid container spacing={2} sx={myStyles.submitContainer}>
                        <Grid item xs={12} md={4} >
                            <BaseTextField
                                label={t('home.request.Name')}
                                fullWidth
                                variant="outlined"
                                name="name"
                                required
                                onChange={handleChange}
                                value={form.name}
                                error={errors.name && true}
                                helperText={errors.name && t(errors.name) }
                            />
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <BaseTextField
                                label={t('home.request.Email')}
                                fullWidth
                                variant="outlined"
                                name="email"
                                required
                                onChange={handleChange}
                                value={form.email}
                                error={errors.email && true}
                                helperText={errors.email && t(errors.email) }
                            />
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <BaseTextField
                                label={t('home.request.Phone')}
                                required
                                fullWidth
                                onChange={handleChange}
                                name="phone"
                                id="formatted-phone-input"
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                                variant="outlined"
                                value={form.phone}
                                error={errors.phone && true}
                                helperText={errors.phone && t(errors.phone) }
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={myStyles.submitContainer}>
                        <Grid item xs={12} sx={{textAlign:'center'}}>
                            <Button variant="contained" sx={myStyles.button} disabled={isProcessing} onClick={submitHandler}>
                                {!isProcessing && t('home.request.DemandeInformations')}
                            </Button>
                        </Grid>
                    </Grid>
                </FormRequestInfo>

            </Container>
        </Box>
    );
}

export default CallToAction;