import React from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Container, Grid, Icon, IconButton, styled, Typography} from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import theme from "../../theme";


const myStyles = {
    title: {
        position: 'relative',
        marginBottom: '1rem',
        '&:after':{
            left: 0,
            width: '64px',
            bottom: '-8px',
            height: '5px',
            content: '" "',
            position: 'absolute',
            background: theme.palette.primary.dark,
        }
    },
    email:{
        textDecoration: 'none',
    },
    contactIcon:{
        color: theme.palette.primary.dark,
        marginRight:'5px',
    },
    icon:{
        color: theme.palette.primary.dark,
        '&:hover':{
            background: 'none',
            color: theme.palette.primary.light,
        }
    }
};

const SiteFooter = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0, 0),
    marginTop: theme.spacing(8),
}));

const ContactInfo = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
        padding: 0
    },
}));

const ContactDetails = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
    marginBottom: '1rem',
}));

const ContactDetail = styled('div')(({ theme }) => ({
    display: 'flex',
    margin:'8px 0'
}));

const Socials = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
        padding: 0
    },
}));

function Footer(props) {
    const {t} = useTranslation();

    function handleClick(icons) {
        switch (icons) {
            case 'facebook':
                window.open('https://www.facebook.com/Nessapp.ca/', '_blank');
                break;
            case 'instagram':
                window.open('https://www.instagram.com/nessapp.ca/?hl=fr', '_blank');
                break;
            case 'linkedin':
                window.open('https://www.linkedin.com/company/nessappca/', '_blank');
                break;
            default:
                break;
        }
    }

    return (
        <>
            <SiteFooter>
                <Container justify="center">
                    <Grid container>
                        <Grid item lg={6} md={6} sm={12}>
                            <Box sx={{padding: '16px 0'}}>
                                <Typography variant="h5" sx={myStyles.title}>
                                    {t('footer.About')}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" gutterBottom>
                                    {t('footer.AboutText')}
                                </Typography>
                             </Box>
                        </Grid>
                        <Grid item lg={3} md={3} sm={12}>
                            <ContactInfo>
                                <Typography variant="h5" sx={myStyles.title} >
                                    {t('footer.Contact')}
                                </Typography>
                                <ContactDetails>
                                    <ContactDetail>
                                        <Icon sx={myStyles.contactIcon}>mail</Icon>
                                        <Typography variant="body2" color="textSecondary" gutterBottom sx={myStyles.email} component="a" href="mailto:info@nessapp.ca">
                                            info@nessapp.ca
                                        </Typography>
                                    </ContactDetail>
                                    <ContactDetail>
                                        <Icon sx={myStyles.contactIcon}>location_on</Icon>
                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            {t('footer.Address')}
                                        </Typography>
                                    </ContactDetail>
                                </ContactDetails>
                            </ContactInfo>
                        </Grid>
                        <Grid item lg={3} md={3} sm={12}>
                            <Socials>
                                <Typography variant="h5" sx={myStyles.title} >
                                    {t('footer.Disclaimer')}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" gutterBottom>
                                    {t('footer.DisclaimerText')}
                                </Typography>
                                <IconButton aria-label="facebook" sx={myStyles.icon} onClick={() => handleClick('facebook')}>
                                    <FacebookIcon fontSize="large" />
                                </IconButton>
                                <IconButton aria-label="facebook" sx={myStyles.icon} onClick={() => handleClick('instagram')}>
                                    <InstagramIcon fontSize="large" />
                                </IconButton>
                                <IconButton aria-label="facebook" sx={myStyles.icon} onClick={() => handleClick('linkedin')}>
                                    <LinkedInIcon fontSize="large" />
                                </IconButton>
                            </Socials>
                        </Grid>
                    </Grid>
                </Container>
            </SiteFooter>
        </>
    );
}

export default Footer;