import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Button, Card, Container, Grid, Icon, styled, Typography} from "@mui/material";
import theme from "../../../theme";

const cardList = [
    {
        imgIcon: "bilansante.png",
        title: "home.about.BilanBoxTitle",
        attribut:[
            "home.about.BilanBoxAttribut1",
            "home.about.BilanBoxAttribut2",
            "home.about.BilanBoxAttribut3",
        ],
    },
    {
        imgIcon: "rendezvous.png",
        title: "home.about.GestionBoxTitle",
        attribut: [
            "home.about.GestionBoxAttribut1",
            "home.about.GestionBoxAttribut2",
            "home.about.GestionBoxAttribut3",
        ],
    },
    {
        imgIcon: "gestion.png",
        title: "home.about.ZeroPapierBoxTitle",
        attribut: [
            "home.about.ZeroPapierBoxAttribut1",
            "home.about.ZeroPapierBoxAttribut2",
        ],
    },
    {
        imgIcon: "fichesclients.png",
        title: "home.about.FichesClientsBoxTitle",
        attribut: [
            "home.about.FichesClientsBoxAttribut1",
            "home.about.FichesClientsBoxAttribut2",
            "home.about.FichesClientsBoxAttribut3",
        ],
    },
];

const AboutTitle = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2rem',
}));
const CardDetails = styled('div')(({ theme }) => ({
    textAlign: 'left',
    marginLeft: '6rem',
    padding: theme.spacing(2),
    minHeight: '170px',
    [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
    },
}));

const myStyles = {
    section: {
        paddingTop: '5rem',
        paddingBottom: '5rem',
    },
    aboutContainer: {
        borderRadius: '34px',
        backgroundColor: theme.palette.primary.dark,
        padding: '2rem',
    },
    card: {
        transition: "all 400ms ease-in-out",
        position: "relative",
        overflow: "hidden",
        borderRadius: "32px",
        minHeight: "246px",
        boxShadow: theme.shadows[6],
        padding: theme.spacing(2),
        "& .card-icon": {
            position: "absolute",
            fontSize: 200,
            height: 200,
            width: 200,
            left: -80,
            bottom: -80,
            zIndex: 1,
            opacity: 0.24,
            transition: "all 400ms ease-in-out",
        },

        "&:hover": {
            transform: "translateY(-8px)",

            "& .card-icon": {
                transform: "translateY(-8px)",
                color: theme.palette.secondary.main,
                opacity: 0.6,
            },
        },
    },
}

function AboutNessapp(props) {
    const {t} = useTranslation();

    return (
        <Box id="apropos" sx={myStyles.section}>
            <Container  sx={myStyles.aboutContainer}>
                <AboutTitle>
                    <Typography variant="h2" color="textPrimary" sx={{color:'white'}}>
                        {t('home.about.AboutTitle')}
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{color:'white'}}>
                        {t('home.about.AboutSubtitle')}
                    </Typography>
                </AboutTitle>
                <div className="text-center">
                    <Grid container spacing={5}>
                        {cardList.map((card, index) => (
                            <Grid item lg={6} md={6} sm={12} xs={12} key={index}>
                                <Card sx={myStyles.card} elevation={5}>
                                    <img src={`../../images/${card.imgIcon}`} alt={card.imgIcon} className="homeIconImage"/>
                                    <CardDetails>
                                        <Typography variant="h5" sx={{marginBottom:'10px'}}>
                                            {t(card.title)}
                                        </Typography>
                                        {
                                            card.attribut.map((attribut, index) => (
                                                <Typography variant="body2" color="textSecondary" gutterBottom key={index}>
                                                    - {t(attribut)}
                                                </Typography>
                                            ))
                                        }
                                    </CardDetails>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </Container>
        </Box>
    );
}

export default AboutNessapp;