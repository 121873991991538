import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import { PasswordRule } from 'components';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PasswordValidator from 'password-validator';
import apiClient from "services/apiClient";
import {placeDollars} from 'utils/utils';
import {PORTAIL_URL, TPS, TVQ} from '../../../utils/config';
import Swal from "sweetalert2";
import {
	Backdrop,
	Box,
	Button,
	Checkbox, CircularProgress,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	InputAdornment,
	Paper, Radio,
	RadioGroup,
	Typography
} from "@mui/material";
import {BaseTextField} from "components/textfield/StyledTextField";
import MapAutoComplete from "components/textfield/MapAutoComplete";
import NumberFormat from 'react-number-format';
import theme from "../../../theme";

const myStyles = {
	button:{
		fontSize: '1.2rem',
		color: theme.palette.primary.dark,
		backgroundColor: 'transparent',
		border:'2px solid',
		borderColor: theme.palette.primary.dark,
		borderRadius: '15px',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			color:theme.palette.primary.dark,
		}
	},
	paper: {
		padding: theme.spacing(2),
	},
	price:{
		fontWeight:"Bold",
		fontSize: "28px",
		textAlign:"right"
	},
	submitContainer:{
		marginTop: theme.spacing(2),
	},
	subtitle: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2)
	}
};

const initialState = {agreement : false, langPreferences: 'fr', centerAddress:""};

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
	const { onChange, ...other } = props;
	return (
		<NumberFormat
			{...other}
			getInputRef={ref}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			format="(###) ###-####" allowEmptyFormatting mask="_"
		/>
	);
});

function RegisterPage(props) {
	const {t} = useTranslation();
	const params = useParams()
	const planid = params?.planid ? params.planid : 1
	const navigate = useNavigate();
	const [errors, setErrors] = useState({});
	const [isProcessing, setIsProcessing] = useState(false)
	const [isFetching, setIsFetching] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [price, setPrice] = useState(0)
	const [lang, setLanguage] = useState('fr')
	const [subscriptionTier, setSubscriptionTier] = useState([])
	const [form, setForm] = useState(initialState);
	const [pwdError, setPwdError] = useState(['min', 'uppercase', 'lowercase', 'digits', 'symbols'])

	useEffect(() => {
		setIsFetching(true)
		apiClient.fetchSubscriptionTier().then(({data, error}) => {
			if (error) {
				setErrors({fetching: error})
			} else {
				const {subscriptionTier} = data
				let selectedPlan = subscriptionTier.find(plan => plan.tier_PK === Number(planid))
				setSubscriptionTier(data.subscriptionTier)
				setPrice(selectedPlan.tier_cost)
				setForm({ ...form, subscriptionTier: selectedPlan.tier_name, subscriptionTierId: Number(planid)});
			}
			setIsFetching(false)
		}).catch(err => {
			setErrors({fetching: err})
			setIsFetching(false)
		})
	}, [])

	const handleShowPassword = () => setShowPassword(!showPassword);

	const handleChange = (element) => {
		if ( errors.hasOwnProperty(element.target.name) ) {
			setErrors((e) => ({ ...e, [element.target.name]: null }))
		}

		if (element.target.name === "email") {
			if (element.target.value.indexOf("@") === -1) {
				setErrors((e) => ({ ...e, email: "register.ValidEmail" }))
			} else {
				setErrors((e) => ({ ...e, email: null }))
			}
		}

		if (element.target.name === 'password') {
			validatePasswordSecurity(element.target.value);
		}

		if (element.target.name === "confirmPassword") {
			if (element.target.value !== form.password) {
				setErrors((e) => ({ ...e, confirmPassword: "passwordRule.PwMatchingPassword" }))
			} else {
				setErrors((e) => ({ ...e, confirmPassword: null }))
			}
		}

		setForm({ ...form, [element.target.name]: element.target.value });
	}

	const handleAgreement = (element) => {
		setErrors((e) => ({ ...e, agreement: null }))
		setForm( (e) => ({...e, agreement : element.target.checked}))
	}

	const handleTierChange = (element) => {
		setErrors((e) => ({...e, subscriptionTier: null}))
		const tierSelected = subscriptionTier.find(plan => plan.tier_PK === Number(element.target.value))
		setPrice(Number(tierSelected.tier_cost))
		setForm({ ...form, subscriptionTier: tierSelected.tier_name, subscriptionTierId: Number(element.target.value)});
	}

	function handleLangChange(element) {
		setErrors((e) => ({...e, langPreferences: null}))
		setLanguage(element.target.value)
		setForm({...form, langPreferences: element.target.value})
	}

	const validatePasswordSecurity = (password) => {
		const pwdRule = new PasswordValidator();
		pwdRule
		.is()
		.min(8) // Minimum length 8
			.has()
			.uppercase() // Must have uppercase letters
			.has()
			.lowercase() // Must have lowercase letters
			.has()
			.digits(1) // Must have at least 1 digits
			.has()
			.symbols(1) // Must have at least 1 special character
			.has()
			.not()
		.spaces(); // Should not have spaces

		setPwdError(pwdRule.validate(password, {list : true}))
	}

	const handleChangeAutoComplete = (value) => {
		setErrors((e) => ({ ...e, address: null }))
		setForm({...form, centerAddress : (value?.description ? value.description : '' )})
	}

	async function submitHandler() {
		const requiredFields = [
			'centerName',
			'centerAddress',
			'centerMainPhone',
			'email',
			'password',
			'confirmPassword'];
		let hasError = false;

		setIsProcessing(true)
		setErrors((e) => ({...e, form: null}))

		requiredFields.forEach(field => {
			if (!form.hasOwnProperty(field)) {
				setErrors((e) => ({...e, [field]: "register.RequiredFields"}))
				hasError = true
			}
		})

		Object.entries(form).map(field => {
			if (field[1] === '') {
				setErrors(
					(e) => ({...e, [field[0]]: "register.RequiredFields"}))
				hasError = true
			}
		})

		if (form.confirmPassword !== form.password) {
			setErrors((e) => ({
				...e,
				confirmPassword: "passwordRule.PwMatchingPassword"
			}))
			hasError = true
		} else {
			setErrors((e) => ({...e, confirmPassword: null}))
		}

		if (!form.agreement) {
			setErrors((e) => ({...e, agreement: "register.AgreementRequired"}))
			hasError = true
		}

		if (!form.subscriptionTier) {
			setErrors((e) => ({
				...e,
				subscriptionTier: "register.SubscriptionTierRequired"
			}))
			hasError = true
		}

		if (!hasError) {
			setIsFetching(true)
			const {data, error} = await apiClient.registerCenter(form)
			if (data) {
				apiClient.submitRegistrationPayment(data.user, form.subscriptionTierId).then(({data}) => {
					if (data) {
						window.location.href = data.url
					}else{
						setErrors((e) => ({...e, msg: "app.ErrorStripe"}))
					}
				}).catch(error => {
					setErrors((e) => ({...e, msg: "app.ErrorStripe"}))
				});
			}
			if (error) {
				setErrors({form: error})
			}
			setIsFetching(false)
		}
	}

	return (
		<>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={isFetching}
			>
				<CircularProgress color="primary" size={40}/>
			</Backdrop>
			<Box sx={{pt:5, pb:5}}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={8}>
						<Paper  sx={myStyles.paper}>
							<Typography sx={myStyles.subtitle} variant="h4">{t('register.CenterInfoTitle')}</Typography>
							{errors?.form && <span className="errorMsg">{t(errors.form)}</span>}
							<Grid container spacing={1}>
								<Grid item xs={12} md={6}>
									<BaseTextField
										label={t('register.FirstName')}
										required
										fullWidth
										autoFocus
										variant="outlined"
										name="firstName"
										onChange={handleChange}
										value={form.firstName || ''}
										error={errors?.firstName && true}
										helperText={errors?.firstName && t(errors.firstName) }
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<BaseTextField
										label={t('register.LastName')}
										required
										fullWidth
										autoFocus
										variant="outlined"
										name="lastName"
										onChange={handleChange}
										value={form.lastName || ''}
										error={errors?.lastName && true}
										helperText={errors?.lastName && t(errors.lastName) }
									/>
								</Grid>
								<Grid item xs={12}>
									<BaseTextField
										label={t('register.CenterName')}
										required
										fullWidth
										autoFocus
										variant="outlined"
										name="centerName"
										onChange={handleChange}
										value={form.centerName || ''}
										error={errors?.centerName && true}
										helperText={errors?.centerName && t(errors.centerName) }
									/>
								</Grid>
								<Grid item xs={12} md={10}>
									<MapAutoComplete valueAutoComplete={form.centerAddress} errorAutoComplete={errors.centerAddress && true} helperTextAutoComplete={errors.centerAddress && t(errors.centerAddress) } handleChangeAutoComplete={handleChangeAutoComplete}/>
								</Grid>
								<Grid item xs={12} md={2}>
									<BaseTextField
										label={t('register.Local')}
										fullWidth
										variant="outlined"
										name="local"
										onChange={handleChange}
										value={form.local}
										error={errors.local && true}
										helperText={errors.local && t(errors.local) }
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<BaseTextField
										label={t('register.CenterMainPhone')}
										required
										fullWidth
										onChange={handleChange}
										name="centerMainPhone"
										id="formatted-mainPhone-input"
										InputProps={{
											inputComponent: NumberFormatCustom,
										}}
										variant="outlined"
										value={form.centerMainPhone}
										error={errors.centerMainPhone && true}
										helperText={errors.centerMainPhone && t(errors.centerMainPhone) }
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<BaseTextField
										label={t('register.CenterAltPhone')}
										required
										fullWidth
										onChange={handleChange}
										name="centerAltPhone"
										id="formatted-altPhone-input"
										InputProps={{
											inputComponent: NumberFormatCustom,
										}}
										variant="outlined"
										value={form.centerAltPhone}
										error={errors.centerAltPhone && true}
										helperText={errors.centerAltPhone && t(errors.centerAltPhone) }
									/>
								</Grid>
								<Grid item xs={12}>
									<FormLabel component="legend">{t('register.LangPreference')}</FormLabel>
									<RadioGroup row aria-label="gender" name="row-radio-buttons-group" onChange={handleLangChange} value={lang}>
										<FormControlLabel value="fr" control={<Radio />} label={t('app.French')} />
										<FormControlLabel value="en" control={<Radio />} label={t('app.English')} />
									</RadioGroup>
								</Grid>
							</Grid>
							<Typography sx={myStyles.subtitle} variant="h4">{t('register.CenterCredentials')}</Typography>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<BaseTextField
										label={t('register.Email')}
										required
										fullWidth
										variant="outlined"
										name="email"
										onChange={handleChange}
										value={form.email}
										error={errors.email && true}
										helperText={errors.email && t(errors.email) }
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<BaseTextField
										label={t('register.Password')}
										fullWidth
										required
										type={showPassword? 'text' : 'password'}
										variant="outlined"
										name="password"
										onChange={handleChange}
										value={form.password}
										error={errors.password && true}
										helperText={errors.password && t(errors.password)}
										InputProps={{
											endAdornment: (
												<InputAdornment
													onClick={handleShowPassword}
													className="showPassword"
													position="end">
													{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon /> }
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<BaseTextField
										label={t('register.ConfirmPassword')}
										fullWidth
										required
										type={showPassword? 'text' : 'password'}
										variant="outlined"
										name="confirmPassword"
										onChange={handleChange}
										value={form.confirmPassword}
										error={errors.confirmPassword && true}
										helperText={errors.confirmPassword && t(errors.confirmPassword)}
										InputProps={{
											endAdornment: (
												<InputAdornment
													onClick={handleShowPassword}
													className="showPassword"
													position="end">
													{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon /> }
												</InputAdornment>
											),
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<PasswordRule pwdRuleError={pwdError}/>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={12} md={4}>
						<Paper sx={myStyles.paper}>
							<Typography sx={myStyles.subtitle} variant="h4">{t('register.Subscription')}</Typography>
							{ errors?.fetching ? <span className="errorMsg">{t(errors.fetching)}</span> : null }
							{ errors?.subscriptionTier ? <span className="errorMsg">{t(errors.subscriptionTier)}</span> : null }
							<FormGroup>
								<RadioGroup
									aria-label="price"
									name="radio-buttons-group"
									onChange={handleTierChange}
									defaultValue={planid}
								>
									{ subscriptionTier?.map((tier) => (
										<Grid container justifyContent="space-between" key={tier.tier_name} >
											<Grid item xs={8}>
												<FormControlLabel value={tier.tier_PK} control={<Radio/>} label={t(tier.tier_short_description)}/>
											</Grid>
											<Grid item xs={4}>
												<Typography sx={myStyles.price} variant="subtitle1">{placeDollars(tier.tier_cost.toFixed(2))}</Typography>
											</Grid>
										</Grid>
									))}
								</RadioGroup>
							</FormGroup>
							<ul className="register__orderUl">
								<li className="register__orderLi">
									<strong>{form?.subscriptionTier || t('register.SelectTier')}</strong>
									<span>{placeDollars(price)}</span>
								</li>
								<li className="register__orderLi">
									{t('order.TPS')}
									<span>{placeDollars((price*TPS))}</span>
								</li>
								<li className="register__orderLi">
									{t('order.TVQ')}
									<span>{placeDollars((price*TVQ))}</span>
								</li>
								<li className="register__orderLi">
									<strong>{t('order.Total')}</strong>
									<strong><span>{placeDollars((price + (price*TPS) + (price*TVQ)))}</span></strong>
								</li>
							</ul>
						</Paper>

						<Grid container spacing={2} sx={myStyles.submitContainer}>
							<Grid item xs={12}>
								<Typography variant="body2" align="left">
									{t('home.pricing.TrialTitle')}
								</Typography>
								<Typography variant="body2" align="left">
									{t('home.pricing.TrialDescription')}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<FormGroup>
									<FormControlLabel control={
										<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} onChange={handleAgreement}/>
									} label={t('register.Agreement')}/>
								</FormGroup>
								{errors?.agreement && <span className="errorMsg">{t(errors.agreement)}</span>}
							</Grid>
							<Grid item xs={12}>
								<Button sx={myStyles.button} fullWidth size="large" type="submit" disabled={isFetching} onClick={submitHandler}>
									{!isFetching && t('register.SubmitBtn')}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>

		</>
	);
}

export default RegisterPage;
