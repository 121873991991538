import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import './lang/i18nextConf';

import App from './App';

import theme from './theme'
import {ThemeProvider} from "@emotion/react";
import {CssBaseline} from "@mui/material";

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
            <App />
        </Router>
    </ThemeProvider>,
  document.getElementById('root')
);
