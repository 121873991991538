import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import { Logo, Menu, TranslateLink} from 'components'
import theme from "../../theme";
import {AppBar, Divider, Drawer, IconButton, Toolbar, useMediaQuery} from "@mui/material";
import {useTheme} from "@emotion/react";

const myStyles = {
	appbar:{
		background: theme.palette.primary.main,
	},
	toolbar: {
		display: "flex",
	},
	drawer: {
		width: '35%',
		flexShrink: 0,
		[theme.breakpoints.down("xs")]: {
			width: '100%',
		}
	},
	drawerPaper: {
		width: '35%',
		[theme.breakpoints.down("xs")]: {
			width: '100%',
		}
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
}

function Header(props) {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const theme = useTheme();

	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const [state, setState] = useState({
		drawerOpen: false,
	});

	function displayMobile() {
		const handleDrawerOpen = () =>
			setState((prevState) => ({ ...prevState, drawerOpen: true }));
		const handleDrawerClose = () =>
			setState((prevState) => ({ ...prevState, drawerOpen: false }));

		return <Toolbar>
			<Logo/>
			<IconButton
				{...{
					edge: "end",
					color: "primary",
					"aria-label": "menu",
					"aria-haspopup": "true",
					onClick: handleDrawerOpen,
				}}
			>
				<MenuIcon />
			</IconButton>
			<Drawer
				{...{
					anchor: "right",
					open: state.drawerOpen,
					onClose: handleDrawerClose,
				}} sx={myStyles.drawer}
				classes={{
					paper: myStyles.drawerPaper,
				}}
			>
				<div>
				{/*<div className={classes.drawerHeader}>*/}
					<IconButton onClick={handleDrawerClose}>
						<CloseIcon />
					</IconButton>
				</div>
				<Divider />
				<Menu />
				{/*<TranslateLink />*/}
			</Drawer>
		</Toolbar> ;
	}

	function displayDesktop() {
		return <Toolbar sx={{justifyContent: 'flex-end'}}>
			{/*<Logo/>*/}
			<Menu />
			{/*<TranslateLink />*/}
		</Toolbar>;
	}

	return (
		<>
			<AppBar sx={myStyles.appbar} position="static">{
				// isMobile ? displayMobile() : displayDesktop()
				displayDesktop()
			}</AppBar>
		</>
	);
}

export default Header;