import { Route, Routes } from "react-router-dom";
import {Header, RegisterPage, Footer} from 'components';
import './asset/css/App.css';

const App = () => {
    return (
        <>
            <Header/>
                <Routes>
                    <Route path="/" element={<RegisterPage/>} />
                    <Route path="/:planid" element={<RegisterPage/>} />
                </Routes>
            <Footer/>
        </>
    )
}

export default App
